import { EnvelopeIcon } from '@heroicons/react/24/outline'
import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import type { ActionFunction, LoaderFunction } from '@remix-run/node'
import { json, redirect } from '@remix-run/node'
import { Form, Link, NavLink, useActionData, useSearchParams } from '@remix-run/react'
import { withZod } from '@remix-validated-form/with-zod'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { SocialsProvider } from 'remix-auth-socials'
import { z } from 'zod'
import FormInput from 'app/components/form/FormInput'
import FormPassword from 'app/components/form/FormPassword'
import { SmartForm } from 'app/components/form/SmartForm'
import Spinner from 'app/components/Spinner'
import { User } from 'app/models/User'
import { notify, notifyError } from 'app/services/notify.client'
import { createUserSession, getUser, SESSION_DURATION } from 'app/session.server'
import { safeRedirect, validateEmail } from 'app/utils'
import { debug, logError, log } from 'lib/logging'
import { sendEmail } from 'lib/sendEmail.server'
import { sendSMS } from 'lib/sendSMS.server'
import { setCookie } from 'lib/setCookie.client'

export const loader: LoaderFunction = async ({ request }) => {
  const user = await getUser(request)
  debug(`login`, `user!?`, user?.id)
  debug(`login`, `request`, request)
  if (user) {
    return redirect(`/`)
  }

  return json({})
}

interface ActionData {
  error?: string
  getCode?: boolean
  channel?: string
  email?: string
  password?: string
  tryOtherLogin?: boolean
}

export const action: ActionFunction = async ({ request }) => {
  try {
    const formData = await request.formData()
    const email = formData.get(`email`) as string
    const password = formData.get(`password`) as string
    const code: string = [
      formData.get(`code1`),
      formData.get(`code2`),
      formData.get(`code3`),
      formData.get(`code4`),
      formData.get(`code5`),
      formData.get(`code6`),
    ].join(``)
    const url = new URL(request.url)
    const searchParams = new URLSearchParams(url.search)
    const redirectTo = safeRedirect(searchParams.get(`redirectTo`) || `/`)

    log(
      `[auth]`,
      `login attempt from`,
      request.headers.get(`x-forwarded-for`) || request.headers.get(`x-real-ip`) || request,
      email,
    )

    debug(`login`, `action func`, email, password, code, redirectTo)

    if (!validateEmail(email)) {
      return json<ActionData>({ error: `Email is invalid` }, { status: 401 })
    }

    if (typeof password !== `string` || password.length === 0) {
      return json<ActionData>({ error: `Password is required` }, { status: 401 })
    }

    if (password.length < 8) {
      return json<ActionData>({ error: `Password is too short` }, { status: 401 })
    }
    const user = await User.authenticate(email, password)

    if (user === false) {
      return json({ tryOtherLogin: true })
    } else if (user === undefined) {
      log(`[auth]`, `invalid login attempt`, email)
      return json<ActionData>({ error: `Invalid email or password` }, { status: 401 })
    }

    debug(`login`, `have phone?`, user.phone)

    let channel = user.preferredChannel || user.phone ? `SMS` : `email`

    // SEND CODE if no MFA in 12 hours
    let needsMFA = !user.lastLoginDate || Date.now() - user.lastLoginDate.getTime() > SESSION_DURATION * 1000
    debug(`login`, `code?`, code, user.lastLoginDate, needsMFA)
    if (!code && needsMFA) {
      try {
        let code: string
        if (user.authCode && user.authCodeDate!.getTime() > Date.now() - 1000 * 60 * 60) {
          code = user.authCode
        } else {
          code = user.authCode = [...Array(6)].map((_) => (Math.random() * 10) | 0).join(``)
          user.authCodeDate = new Date()
          await user.save()
        }

        let message = `Your Trackable verification code is: ${code}. Don't share this code with anyone; our employees will never ask for the code.`

        if (channel === `SMS`) await sendSMS(user.phone!, message)
        else await sendEmail([user.email], `Your Trackable verification code is: ${code}`, message)

        log(`[auth]`, `sent code to`, email)
        return { getCode: true, channel, email, password }
      } catch (err) {
        logError(`error sending code`, err)
      }

      // the user has entered a code
    } else if (code) {
      let valid = user.authCode === code
      debug(`login`, `is the code valid?`, valid)
      if (!valid) {
        log(`[auth]`, `invalid code`, email)
        return { error: `Invalid Code`, getCode: true, channel, email, password }
      }
    }

    log(`[auth]`, `login successful for`, email)

    return await createUserSession({
      request,
      user: user!,
      redirectTo,
    })
  } catch (err) {
    logError(`caught error trying to login`, err)
    return redirect(`/login`)
  }
}

const loginValidator = withZod(z.object({ email: z.string().email(), password: z.string() }))

export const meta = () => {
  return [
    {
      title: `Login`,
    },
  ]
}

export default function LoginPage(): React.ReactElement {
  const [searchParams] = useSearchParams()
  const redirectTo = safeRedirect(searchParams.get(`redirectTo`) || `/`)
  const codeForm = useRef<HTMLFormElement>(null)
  const [email, setEmail] = useState(``)
  const [loading, setLoading] = useState(false)
  const actionData = useActionData() as ActionData
  const channelRef = useRef<HTMLInputElement>(null)

  debug(`login`, `redirect after login: `, redirectTo)

  useEffect(() => {
    if (redirectTo !== `/`) {
      setCookie(`returnTo`, redirectTo, moment.utc().add(3, `minute`).toString())
    }
  }, [])

  function checkSubmit() {
    let ready = [1, 2, 3, 4, 5, 6].every((i) => {
      let d = document.querySelector(`[name=code${i}]`)?.[`value`]
      return d?.match(/^[0-9]$/)
    })
    if (ready) {
      codeForm.current?.submit()
    }
  }

  function resend() {
    ;[1, 2, 3, 4, 5, 6].forEach((i) => {
      document.querySelector(`[name=code${i}]`)![`value`] = ``
    })
    codeForm.current?.submit()
  }

  useEffect(() => {
    if (actionData?.tryOtherLogin) {
      notify(`You do not have a password. Please log in with Google or set a password with forgot your password.`)
      setLoading(false)
    }
    if (actionData?.error) {
      notifyError?.(actionData.error)
      setLoading(false)
    }
  }, [actionData])

  return (
    <div className="grid h-screen place-items-center overflow-y-auto bg-trn-50">
      <div className="max-w-[95vw] rounded border border-trn-200 bg-white p-20 text-center shadow">
        {actionData?.getCode ? (
          <Form ref={codeForm} method="post">
            <h3 className="mb-5">Check your {actionData?.channel === `email` ? `Email` : `texts`}</h3>
            <p className="mb-8 text-center text-slate-800">We sent you a code</p>
            <input type="hidden" name="email" value={(actionData?.email as string) || ``} />
            <input type="hidden" name="password" value={(actionData?.password as string) || ``} />
            <div className="flex">
              {[1, 2, 3, 4, 5, 6].map((i) => (
                <input
                  autoFocus={i === 1}
                  className="m-2 my-8 w-[6rem] rounded text-center text-7xl focus:outline-none focus:ring-4 focus:ring-slate-300"
                  key={`code${i}`}
                  type="tel"
                  name={`code${i}`}
                  maxLength={1}
                  tabIndex={i}
                  autoComplete="off"
                  placeholder=""
                  onPaste={(event) => {
                    event.preventDefault()
                    let code = event.clipboardData.getData(`text`)
                    if (!code.match(/^[0-9]+$/)) return
                    let digits = code.split(``)
                    digits.forEach((d, i) => {
                      document.querySelector(`[name=code${i + 1}]`)?.setAttribute(`value`, d || ``)
                    })
                    checkSubmit()
                  }}
                  onKeyDown={(event) => {
                    // keep non-numbers from being entered
                    if (
                      event.metaKey ||
                      event.ctrlKey ||
                      event.altKey ||
                      [`Tab`, `Backspace`, `Delete`].includes(event.key)
                    )
                      return
                    if (!event.key.match(/[0-9]/)) return event.preventDefault()
                  }}
                  onKeyUp={(event) => {
                    if (!event.key.match(/[0-9]/)) return
                    // @ts-ignore
                    document.querySelector(`[name=code${i + 1}]`)?.focus()
                    checkSubmit()
                  }}
                />
              ))}
            </div>
            <input type="hidden" name="channel" value={actionData?.channel || ``} ref={channelRef} />
            <button
              type="submit"
              className={
                `btn-primary col-span-6 my-8 w-full items-center rounded-full border border-transparent bg-blue-300` +
                `bg-blue-600 p-5 text-center text-sm font-medium text-white shadow-sm focus:outline-none ` +
                `focus:ring-2 focus:ring-blue-600 focus:ring-offset-2`
              }
            >
              Submit Code
            </button>
            <div className="my-5 text-center">
              Didn't receive it?{` `}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" className="btn-link" onClick={() => resend()}>
                Click to resend
              </a>
            </div>
            <Link to="/login" className="block text-center font-bold">
              <ArrowLeftIcon className="inline h-5 w-5" /> Back to login
            </Link>
          </Form>
        ) : (
          <div className="inline-block w-[500px] max-w-[95vw]">
            <h2 className="mb-5"> Log in to your account</h2>
            <p className="mb-[4rem] text-black">Welcome back! Please enter your details.</p>
            <Form method="post" action={`/auth/${SocialsProvider.GOOGLE}`} className="col-span-6 mt-5 w-full">
              <button className="w-full rounded-full border-2 border-trn-200 bg-white p-3 text-black outline-none">
                <img
                  alt="Google Logo"
                  src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                  className="mr-1 inline h-5 w-5"
                />
                Log in with Google
              </button>
            </Form>

            <div className="separator my-5 text-black">OR</div>
            <SmartForm
              id="login-form"
              method="post"
              className="w-full"
              noValidate
              validator={loginValidator}
              hideButtons={true}
              hiddenInputs={[{ name: `redirectTo`, value: redirectTo }]}
            >
              <FormInput
                icon={<EnvelopeIcon />}
                cols={6}
                label="Email Address"
                id="email"
                required
                autoFocus={true}
                name="email"
                type="email"
                autoComplete="email"
                aria-describedby="email-error"
                className="w-full rounded border border-gray-500 px-2 py-1 text-lg"
                value={email}
                onChange={(event) => setEmail(event.currentTarget.value)}
              />

              <FormPassword label="Password" name="password" cols={6} />

              <button
                id="login"
                type="submit"
                className={`btn btn-primary btn-lg col-span-6 items-center`}
                onClick={() => setLoading(true)}
              >
                {loading ? <Spinner /> : `Log in`}
              </button>

              <NavLink
                to={`/forgot?email=${encodeURIComponent(email)}`}
                className="col-span-6 mt-0 block pt-0 text-center text-black"
              >
                Forgot your password?
              </NavLink>
            </SmartForm>
          </div>
        )}
      </div>
    </div>
  )
}
